import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import LayoutRoot from "../components/LayoutRoot"
import { Link } from "gatsby"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  > h1 {
    margin: 0;
  }

  > a {
    text-decoration: none;
    font-size: 20px;
    color: #f36f21;
  }
`

const NotFoundPage = () => (
  <LayoutRoot withHeader={false} withFooter={false}>
    <Seo title="404: Not found" />
    <Container>
      <h1>
        404 Not Found{" "}
        <span role="img" aria-label="OOPS">
          🤕
        </span>
      </h1>
      <Link to="/">
        <p>Home Page</p>
      </Link>
    </Container>
  </LayoutRoot>
)

export default NotFoundPage
